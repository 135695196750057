import {
  APP_NAME,
  FASHION_APP_NAME,
  FASHION_COHORT,
  MEOWZ_APP_NAME,
  MEOWZ_COHORTS,
} from 'root-constants'

export const getAppName = (currentCohort: string): string => {
  if (MEOWZ_COHORTS.includes(currentCohort)) {
    return MEOWZ_APP_NAME
  }

  if (FASHION_COHORT.includes(currentCohort)) {
    return FASHION_APP_NAME
  }

  return APP_NAME
}
