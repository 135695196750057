import {
  CALENDLY_URI,
  IS_USER_HAS_SUBSCRIPTION,
  USER_EMAIL,
} from 'pages/final/constants'
import { CALENDLY_POST_REQUEST_OPTIONS } from '../constants/calendly'

export const removeStorageData = () => {
  localStorage.removeItem(IS_USER_HAS_SUBSCRIPTION)
  localStorage.removeItem(CALENDLY_URI)
  localStorage.removeItem(USER_EMAIL)
}

export const cancelCalendlyEvent = () => {
  const uri = localStorage.getItem(CALENDLY_URI)

  if (!uri) return
  removeStorageData()

  fetch(`${uri}/cancellation`, CALENDLY_POST_REQUEST_OPTIONS)
    .then((response) => response.json())
    .catch((err) => console.error(err))
}
