import { TAnswers, TQuestionFirstAnswer } from 'models/common.model'

export const getQuestionValues = (
  answers: Partial<TAnswers> | null,
  fields: string[],
): Partial<TQuestionFirstAnswer> | null => {
  if (!answers) {
    return null
  }

  return Object.entries(answers).reduce((acc, [pageId, answer]) => {
    const fieldName = fields.find((field) => pageId.startsWith(field))

    if (!fieldName) return acc

    return {
      ...acc,
      [fieldName]: Array.isArray(answer) ? answer[0] : answer,
    }
  }, {} as Partial<TQuestionFirstAnswer>)
}
