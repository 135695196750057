import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { getConfigAction, setAppName } from 'root-redux/actions/common'
import { getAppName } from 'helpers/getAppName'

export const useInitAppConfig = (): void => {
  const dispatch = useDispatch()
  const { search } = window.location
  const urlParams = new URLSearchParams(search)
  const currentCohort = urlParams.get('cohort') || ''
  const appName = useMemo(() => getAppName(currentCohort), [currentCohort])

  useEffect(() => {
    dispatch(setAppName(appName))
    dispatch(getConfigAction(appName))
  }, [dispatch, appName])
}
