import 'firebase/auth'
import 'firebase/remote-config'
import { IS_USER_HAS_SUBSCRIPTION } from 'pages/final/constants'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUserStatus } from 'root-redux/selects/user'
import { cancelCalendlyEvent } from 'services/calendly.service'

export const useDestroyBeforeUnmountComponent = (): void => {
  const userStatus = useSelector(selectUserStatus)

  useEffect(
    () => () => {
      const isNotPaid =
        !userStatus?.hasSubscription ||
        !localStorage.getItem(IS_USER_HAS_SUBSCRIPTION)

      window.addEventListener('beforeunload', async (event) => {
        if (isNotPaid) {
          await cancelCalendlyEvent()
        }

        event.preventDefault()
      })
    },
    [userStatus?.hasSubscription],
  )
}
