import { IRequestOptions, IResponseResult } from 'models/api.model'
import { RequestMethod } from 'root-constants'
import { ApiService } from 'services/api.service'
import { IRawVariant } from 'models/variant.model'
import { TAnswer, TQuizAnswer } from 'models/common.model'

export class QuizApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  sendAnswer({
    uuid,
    appName,
    isFinished,
    isSkipped,
    question,
    answers,
  }: {
    uuid: string
    appName: string
    isFinished?: boolean
    isSkipped?: boolean
    question: string
    answers: TAnswer
  }): Promise<IResponseResult<IRawVariant>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        is_finished: isFinished,
        is_skipped: isSkipped,
        quiz: {
          [question]: answers,
        },
        version: '1.0',
      },
    }

    return this.api.makeRequest<IRawVariant>(
      `v2/leads/${uuid}/${appName}/quiz`,
      options,
    )
  }

  sendAnswers({
    uuid,
    appName,
    isFinished,
    isSkipped,
    question,
    answers,
    quiz,
  }: {
    uuid: string
    appName: string
    isFinished?: boolean
    isSkipped?: boolean
    question: string
    answers: TAnswer
    quiz: TQuizAnswer
  }): Promise<IResponseResult<IRawVariant>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        question,
        answers,
        is_finished: isFinished,
        is_skipped: isSkipped,
        quiz,
        version: '1.0',
      },
    }

    return this.api.makeRequest<IRawVariant>(
      `v2/leads/${uuid}/${appName}/quiz`,
      options,
    )
  }
}
