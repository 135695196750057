import { IRequestOptions, IResponseResult } from 'models/api.model'
import { RequestMethod } from 'root-constants'
import { ApiService } from 'services/api.service'
import { IRawSubscription } from 'models/subscriptions.model'

export class SubscriptionsApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getSubscriptionsList({
    uuid,
    appName,
    cohort,
    tags = ['no_tax'],
    type,
  }: {
    uuid: string
    appName: string
    cohort: string
    type: string
    tags?: string[]
  }): Promise<IResponseResult<{ plans: IRawSubscription }>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: {
        plan_type: type,
        uuid,
        tags: tags.join(','),
      },
    }

    return this.api.makeRequest<{ plans: IRawSubscription }>(
      `v2/plans/${appName}/${cohort}`,
      options,
    )
  }
}
