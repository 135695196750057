import firebase from 'firebase'
import {
  DEFAULT_WEB_FUNNELS_CONFIG_FILE,
  FirebaseRemoteConfigKey,
} from 'root-constants'
import { getWebFunnelsConfigFromRawData } from 'helpers/getWebFunnelsConfigFromRawData'
import { IRemoteConfigFile } from 'models/config.model'

export const getWebFunnelsConfig = (
  firebaseRemoteConfig: firebase.remoteConfig.RemoteConfig | null,
): IRemoteConfigFile => {
  const value = firebaseRemoteConfig?.getString(
    FirebaseRemoteConfigKey.WEB_FUNNELS_CONFIG_FILE,
  )

  if (typeof value === 'string') {
    return getWebFunnelsConfigFromRawData(JSON.parse(value))
  }

  return DEFAULT_WEB_FUNNELS_CONFIG_FILE
}
