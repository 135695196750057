import { combineReducers } from 'redux'
import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { routeHistory } from 'route-history'

import { commonReducer } from 'root-redux/reducers/common'
import { userReducer } from 'root-redux/reducers/user'
import { accountReducer } from 'modules/account/redux'
import { IAppState } from 'models/store.model'
import { paymentReducer } from 'modules/payment/redux/reducer'

export const rootReducer = combineReducers<IAppState>({
  router: createRouterReducer(routeHistory),
  common: commonReducer,
  user: userReducer,
  payment: paymentReducer,
  account: accountReducer,
})
