import { StripeFieldName } from 'root-constants'
import { TPayPallStyle } from 'modules/payment/types'

export const PAYMENT_FIELD_NAMES = {
  [StripeFieldName.NAME]: 'Cardholder name',
  [StripeFieldName.NUMBER]: 'Card number',
  [StripeFieldName.EXPIRY]: 'Expiry date',
  [StripeFieldName.CVC]: 'CVC',
  [StripeFieldName.EMAIL]: 'E-mail',
}

export const EMPTY_FIELD_ERROR = `can't be blank`

export const PAYMENT_FORM_ERRORS = {
  [StripeFieldName.NUMBER]: 'Please, check card number',
  [StripeFieldName.EXPIRY]: 'Please, check expiry date of your card',
  [StripeFieldName.CVC]: 'Please, check CVV/CVC',
}

export const DEFAULT_CARDHOLDER_NAME = ' '

export const DEFAULT_ERROR_DESCRIPTION =
  'Please check your payment information or contact your bank and try again, or try a new payment method.'

export const enum StripeErrorCode {
  CARD_DECLINED = 'card_declined',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const enum PaymentStatus {
  SUCCESS = 'success',
}

export const enum PaymentSystem {
  PAYPAL = 'paypal',
  STRIPE = 'stripe',
}

export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'

export const enum oldPrice {
  ONE_MONTH = 59.99,
  THREE_MONTH = 79.99,
  SIX_MONTH = 99.99,
  YEAR = 159.99,
}

export const defaultPayPallStyle: TPayPallStyle['style'] = {
  label: 'paypal',
  tagline: false,
  layout: 'horizontal',
  height: 50,
  color: 'silver',
  shape: 'pill',
}

export const NUMBER_OF_PLANS_ON_PAGE = 3

export const STRIPE_ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Nunito:wght@600;700&display=block',
    },
  ],
}

export const enum StripeSoftDeclineReason {
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  DO_NOT_HONOR = 'do_not_honor',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INVALID_AMOUNT = 'invalid_amount',
  PROCESSING_ERROR = 'processing_error',
  REENTER_TRANSACTION = 'reenter_transaction',
  SERVICE_NOT_ALLOWED = 'service_not_allowed',
  TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
}

export const STRIPE_SOFT_DECLINE_REASONS = [
  StripeSoftDeclineReason.AUTHENTICATION_FAILURE,
  StripeSoftDeclineReason.DO_NOT_HONOR,
  StripeSoftDeclineReason.INSUFFICIENT_FUNDS,
  StripeSoftDeclineReason.INVALID_AMOUNT,
  StripeSoftDeclineReason.PROCESSING_ERROR,
  StripeSoftDeclineReason.REENTER_TRANSACTION,
  StripeSoftDeclineReason.SERVICE_NOT_ALLOWED,
  StripeSoftDeclineReason.TRANSACTION_NOT_ALLOWED,
]
