import { IUserStatus } from 'models/user.model'
import { AnyAction } from 'redux'
import {
  SET_EMAIL,
  SET_PHONE,
  SET_SECRET,
  SET_STATUS,
  SET_UUID,
} from '../actions/user'

export interface IUserState {
  uuid: string
  secret: string
  status: IUserStatus | null
  email: string
  phone: string
}

const initState: IUserState = {
  uuid: '',
  secret: '',
  status: null,
  email: '',
  phone: '',
}

export function userReducer(
  state: IUserState = initState,
  { type, payload = null }: AnyAction,
): IUserState {
  switch (type) {
    case SET_UUID: {
      return {
        ...state,
        uuid: payload,
      }
    }
    case SET_SECRET: {
      return {
        ...state,
        secret: payload,
      }
    }
    case SET_STATUS: {
      return {
        ...state,
        status: payload,
      }
    }
    case SET_EMAIL: {
      return {
        ...state,
        email: payload,
      }
    }
    case SET_PHONE: {
      return {
        ...state,
        phone: payload,
      }
    }
    default:
      return state
  }
}
