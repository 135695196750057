import { createStore, Store } from 'redux'

import { IAction, IAppState } from 'models/store.model'
import { rootReducer } from './rootReducer'
import { enhancer } from './enhancer'

export type TAppStore = Store<IAppState>

export const store: TAppStore = createStore<IAppState, IAction<any>, any, any>(
  rootReducer,
  enhancer,
)
