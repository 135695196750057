import { IAppState } from 'models/store.model'
import { IUserStatus } from 'models/user.model'

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectUserStatus = (state: IAppState): IUserStatus | null =>
  state.user.status
export const selectEmail = (state: IAppState): string => state.user.email
export const selectPhone = (state: IAppState): string => state.user.phone

export const selectIsUpsellPaid = (state: IAppState): boolean =>
  !!state.user.status?.upsell?.isPaid

export const selectCountry = (state: IAppState): string =>
  state.user.status?.country || ''
