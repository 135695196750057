import { TimeInterval } from 'root-constants'

// TODO rename to createProductId, after full moving to version v2
export function createProductName({
  periodName,
  periodQuantity,
  price,
}: {
  periodName: TimeInterval | null
  periodQuantity: number | null
  price: number
}): string {
  return `woofz_${periodQuantity}_${periodName}_${price}`
}
