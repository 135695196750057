import {
  getCurrentOldPrices,
  getFormattedPrice,
} from 'helpers/subscriptionPricesHelpers'
import { CENTS_IN_DOLLAR } from 'root-constants'
import { IRawSubscription, ISubscription } from 'models/subscriptions.model'

export const getSubscriptionListFromRawData = (
  rawSubscriptionList: IRawSubscription,
): ISubscription[] =>
  rawSubscriptionList?.plans.map((rawSubscription) => ({
    id: rawSubscription.paywall.show_order.toString(),
    planId: rawSubscription.id,
    product: rawSubscription.product_key,
    paypalPlanId: rawSubscription.paypal?.plan_id,
    isDefault: rawSubscription.paywall.is_default,
    currency: rawSubscription.pricing.currency,
    recurringLookupKey: rawSubscription.stripe.main_lookup_key,
    introLookupKey: rawSubscription.stripe.intro_lookup_key,
    productPrice: rawSubscription.pricing.trial
      ? `${getFormattedPrice(
          rawSubscription.pricing.trial.amount,
        )}+${getFormattedPrice(rawSubscription.pricing.amount)}`
      : `${getFormattedPrice(rawSubscription.pricing.amount)}`,

    mainPrices: {
      periodName: rawSubscription.pricing?.recurring?.interval,
      periodQuantity: rawSubscription.pricing?.recurring?.interval_count,
      fullPrice: getFormattedPrice(rawSubscription.main.current_price.amount),
      fullPriceTax: getFormattedPrice(
        rawSubscription.main.current_price.tax_amount,
      ),
      discountAmount:
        rawSubscription.main.current_price.amount_of_discount / CENTS_IN_DOLLAR,
      daily:
        rawSubscription.main.current_price.in_the_box.daily / CENTS_IN_DOLLAR,
      weekly:
        rawSubscription.main.current_price.in_the_box.weekly / CENTS_IN_DOLLAR,
      monthly:
        rawSubscription.main.current_price.in_the_box.monthly / CENTS_IN_DOLLAR,
      yearly:
        rawSubscription.main.current_price.in_the_box.yearly / CENTS_IN_DOLLAR,

      oldPrices: getCurrentOldPrices(rawSubscription.main.old_prices),
    },

    trialPrices: {
      fullPrice: getFormattedPrice(rawSubscription.trial?.current_price.amount),
      fullPriceTax: getFormattedPrice(
        rawSubscription.trial?.current_price.tax_amount,
      ),
      durationDays: rawSubscription.pricing.trial?.duration_days,
      daily: getFormattedPrice(
        rawSubscription.trial?.current_price.in_the_box.daily,
      ),
      weekly: getFormattedPrice(
        rawSubscription.trial?.current_price.in_the_box.weekly,
      ),
      monthly: getFormattedPrice(
        rawSubscription.trial?.current_price.in_the_box.monthly,
      ),
      yearly: getFormattedPrice(
        rawSubscription.trial?.current_price.in_the_box.yearly,
      ),

      oldPrices:
        rawSubscription.trial &&
        getCurrentOldPrices(rawSubscription.trial.old_prices),
    },
  }))
