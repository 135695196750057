import { ReduxRouter } from '@lagunovsky/redux-react-router'
import { setViewportFullHeight } from 'helpers/setViewportFullHeight'
import { useDestroyBeforeUnmountComponent } from 'hooks/useDestroyBeforeUnmountComponent'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useInitFirebase } from 'hooks/useInitFirebase'
import { useInitGoogleAnalyticsTracker } from 'hooks/useInitGoogleAnalyticsTracker'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSetOptimizeVariantId } from 'hooks/useSetOptimizeVariantId'
import { useSnapPixelInitialization } from 'hooks/useSnapPixelInitialization'
import React, { lazy, Suspense } from 'react'
import { routeHistory } from 'route-history'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import { Spinner } from './Spinner'

const SubscribeFlow = lazy(() => import('components/SubscribeFlow'))

setViewportFullHeight()
smoothScrollPolyfill()

export const App: React.FC = () => {
  useInitAppConfig()
  useInitGoogleAnalyticsTracker()
  useScrollFix()
  useSetOptimizeVariantId()
  useSnapPixelInitialization()
  useInitFirebase()
  useDestroyBeforeUnmountComponent()

  return (
    <ReduxRouter history={routeHistory}>
      <Suspense fallback={<Spinner />}>
        <SubscribeFlow />
      </Suspense>
    </ReduxRouter>
  )
}
