import { IRequestOptions, IResponseResult } from 'models/api.model'
import { ApiService } from 'services/api.service'
import { RequestMethod } from 'root-constants'

export class UnsubscribeApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  unsubscribe(params: {
    data: Record<string, any>
    secret: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.DELETE,
      body: params,
    }

    return this.api.makeRequest<never>('v1/manage/unsubscribe', options)
  }
}
