import { IAppState } from 'models/store.model'
import { DEFAULT_CURRENCY, TimeInterval } from 'root-constants'
import { ISubscription } from 'models/subscriptions.model'
import { TPaymentOptions } from '../types'

export const selectCancelOffer = (state: IAppState): ISubscription | null =>
  state.payment.cancelOffer || null
export const select3DSecureIframeUrl = (state: IAppState): string =>
  state.payment.threeDSecureIframeURL
export const selectPaymentClientSecret = (state: IAppState): string =>
  state.payment.paymentClientSecret
export const selectSubscriptionId = (state: IAppState): string =>
  state.payment.subscriptionId
export const selectIsPaymentFlowsShown = (state: IAppState): boolean =>
  state.payment.isPaymentFlowsShown
export const selectPaymentMethod = (state: IAppState): string =>
  state.payment.paymentMethod

// TODO selectSubscription for second version api, after replace
export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.payment.selectedSubscriptionV2 || null

export const selectPlanId = (state: IAppState): number =>
  state.payment.selectedSubscriptionV2?.planId || 0

export const selectSubscriptionPeriodName = (
  state: IAppState,
): TimeInterval | null =>
  state.payment.selectedSubscriptionV2?.mainPrices.periodName || null

export const selectSubscriptionPeriodQuantity = (
  state: IAppState,
): number | null =>
  state.payment.selectedSubscriptionV2?.mainPrices.periodQuantity || null

export const selectSubscriptionFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscriptionV2?.mainPrices.fullPrice || 0

export const selectSubscriptionOldFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscriptionV2?.mainPrices.oldPrices.fullPrice || 0

export const selectSubscriptionTrialFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscriptionV2?.trialPrices.fullPrice || 0

export const selectTrialDurationDays = (state: IAppState): number =>
  state.payment.selectedSubscriptionV2?.trialPrices.durationDays || 0

export const selectCurrency = (state: IAppState): string =>
  state.payment.selectedSubscriptionV2?.currency || DEFAULT_CURRENCY

export const selectIntroId = (state: IAppState): string | null =>
  state.payment.selectedSubscriptionV2?.introLookupKey || null

export const selectRecurringId = (state: IAppState): string | null =>
  state.payment.selectedSubscriptionV2?.recurringLookupKey || null

export const selectPayPalPlanIdV2 = (state: IAppState): string =>
  state.payment.selectedSubscriptionV2?.paypalPlanId || ''

export const selectProductPrice = (state: IAppState): string =>
  state.payment.selectedSubscriptionV2?.productPrice || ''

export const selectPaymentRequestOptions = (
  state: IAppState,
): TPaymentOptions | null => state.payment.paymentOptions || null
