import { IResponseResult, IRequestOptions } from 'models/api.model'
import {
  IInAppResponseRaw,
  IPurchaseResponseRaw,
  IPurchaseRetrySubscriptionResponseRaw,
  IPurchaseStripeResponseRaw,
} from 'models/payment.model'
import { RequestMethod } from 'root-constants'
import { ApiService } from 'services/api.service'
import { StripeError } from '@stripe/stripe-js'
import { TPriceId, TTrialPriceId } from 'models/variant.model'

export class PaymentApi {
  private readonly baseUrl = 'v1/flow/purchase'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  createSubscription(payload: {
    uuid: string
    priceId: TPriceId
    trialPriceId: TTrialPriceId | null
    trialPeriodDays: number
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid: payload.uuid,
        price_id: payload.priceId,
        trial_id: payload.trialPriceId,
        trial_period_days: payload.trialPeriodDays,
      },
    }

    return this.api.makeRequest<IPurchaseResponseRaw>(this.baseUrl, options)
  }

  createStripeSubscription({
    uuid,
    appName,
    trialPeriodDays,
    cohort,
    planId,
  }: {
    uuid: string
    appName: string
    trialPeriodDays: number
    cohort: string
    planId: number
  }): Promise<IResponseResult<IPurchaseStripeResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        trial_duration: trialPeriodDays,
        cohort,
        plan_id: planId,
      },
    }

    return this.api.makeRequest<IPurchaseStripeResponseRaw>(
      `v2/purchases/stripe/${uuid}/${appName}`,
      options,
    )
  }

  retryPayment(payload: {
    uuid: string
    appName: string
    stripeError: StripeError
  }): Promise<IResponseResult<IPurchaseRetrySubscriptionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_error: payload.stripeError,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `v2/purchases/stripe/${payload.uuid}/${payload.appName}/retry`,
      options,
    )
  }

  createPaypalSubscription({
    uuid,
    paypalPlanId,
    appName,
    cohort,
  }: {
    uuid: string
    paypalPlanId: string | null
    appName: string
    cohort: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        paypal_plan_id: paypalPlanId,
        cohort,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `v2/purchases/paypal/${uuid}/${appName}`,
      options,
    )
  }

  createOneTimePayment(payload: {
    uuid: string
    planId: number
    appName: string
  }): Promise<IResponseResult<IInAppResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        plan_id: payload.planId,
      },
    }

    return this.api.makeRequest<IInAppResponseRaw>(
      `v2/purchases/${payload.uuid}/${payload.appName}/inapp`,
      options,
    )
  }

  getPaymentConfig({
    uuid,
    cohort,
    appName,
  }: {
    uuid: string
    cohort: string
    appName: string
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { uuid },
    }

    return this.api.makeRequest<IPurchaseResponseRaw>(
      `v1/flow/${appName}/variant/${cohort}/payment-config`,
      options,
    )
  }
}
