import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { store } from 'root-redux/store'
import { App } from 'components/App'
import './styles.css'
import './i18n'

import '@fontsource/nunito/400.css'
import '@fontsource/nunito/500.css'
import '@fontsource/nunito/600.css'
import '@fontsource/nunito/700.css'
import '@fontsource/nunito/800.css'
import '@fontsource/nunito/900.css'

import '@fontsource/noto-serif/400.css'
import '@fontsource/noto-serif/500.css'
import '@fontsource/noto-serif/600.css'
import '@fontsource/noto-serif/700.css'
import '@fontsource/noto-serif/800.css'
import '@fontsource/noto-serif/900.css'

import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // Facebook Pixel
    fbq: any
    // AppsFlyer WebSDK
    AF: any
    // ZenDesk plugin
    zE: any
    zESettings: any
    // Google analytics
    gtag: any
    MSStream: any
    // TikTok Pixel
    ttq: any
    // Snap Pixel
    snaptr: any
    // UET Tag Code
    uetq: any
  }
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
