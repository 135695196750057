import { IRequestOptions, IResponseResult } from 'models/api.model'
import { RequestMethod } from 'root-constants'
import { ApiService } from 'services/api.service'
import { IConfigResponseRaw } from 'models/config.model'

export class ConfigApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getConfig(appName: string): Promise<IResponseResult<IConfigResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IConfigResponseRaw>(
      `v1/flow/${appName}/config`,
      options,
    )
  }
}
