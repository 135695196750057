import { TQuestionAnswers } from 'models/common.model'
import { AGE_ANSWERS_MAP, AnswerKey } from 'root-constants'
import { t } from 'i18next'
import { getQuestionValues } from 'helpers/getQuestionValues'
import { getQuestionAnswers } from 'helpers/getQuestionAnswers'

export const prepareAnswersForProfile = (
  answers: Partial<TQuestionAnswers> | null,
  timestamp: number,
) => {
  const globalAnswers = getQuestionValues(answers, [
    AnswerKey.AGE,
    AnswerKey.SHELTER,
    AnswerKey.DOG_NAME,
  ])

  const breedAnswer = getQuestionAnswers(answers, [AnswerKey.DOG_BREED])

  return globalAnswers
    ? {
        ...(globalAnswers?.dog_name && { name: globalAnswers?.dog_name }),
        data: {
          ...(breedAnswer?.dog_breed && {
            selected_breed_name:
              breedAnswer?.dog_breed === 'onboarding.breed.idk' ||
              breedAnswer?.dog_breed === 'onboarding.age.idk'
                ? null
                : t(breedAnswer?.dog_breed),
          }),
          ...(globalAnswers?.dog_age &&
            AGE_ANSWERS_MAP[globalAnswers?.dog_age as string] && {
              age_group_name: AGE_ANSWERS_MAP[globalAnswers?.dog_age as string],
            }),
          ...(Object.hasOwn(globalAnswers, AnswerKey.SHELTER) && {
            is_shelter_dog: globalAnswers?.shelter !== 'no',
          }),
          creation_timestamp: timestamp,
        },
      }
    : {}
}
