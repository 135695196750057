import { userApi } from 'api'
import { getCookie } from 'helpers/getCookie'
import { getUserStatusFromRawUserStatus } from 'helpers/getUserStatusFromRawUserStatus'
import { TAnswers } from 'models/common.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { IGetUUIDResponseRaw, IUserStatus } from 'models/user.model'
import { CUSTOM_TOKEN_LOCAL_STORAGE_KEY } from 'root-constants'
import {
  setAllAnswersAction,
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { selectAnswers, selectAppName } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { eventLogger } from 'services/eventLogger.service'
import { v4 as uuidv4 } from 'uuid'
import { prepareAnswersForProfile } from 'helpers/prepareAnswersForProfile'

const MODULE_NAME = 'USER'

export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const SET_SECRET = `${MODULE_NAME}/SET_SECRET`
export const GET_STATUS = `${MODULE_NAME}/GET_STATUS`
export const SET_STATUS = `${MODULE_NAME}/SET_STATUS`
export const SET_EMAIL = `${MODULE_NAME}/SET_EMAIL`
export const SET_PHONE = `${MODULE_NAME}/SET_PHONE`
export const SEND_FACEBOOK_PARAMS = `${MODULE_NAME}/SET_PHONE`
export const SEND_USER_EMAIL = `${MODULE_NAME}/SEND_USER_EMAIL`
const SEND_USER_INFO = `${MODULE_NAME}/SEND_USER_INFO`
const BIND_USER = `${MODULE_NAME}/BIND_USER`
const SEND_USER_TTCLID = `${MODULE_NAME}/SEND_USER_TTCLID`

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function setUserStatusAction(
  userStatus: IUserStatus,
): IAction<IUserStatus> {
  return {
    type: SET_STATUS,
    payload: userStatus,
  }
}

export function setUserEmailAction(email: string): IAction<string> {
  return {
    type: SET_EMAIL,
    payload: email,
  }
}

export function setUserPhoneAction(phone: string): IAction<string> {
  return {
    type: SET_PHONE,
    payload: phone,
  }
}

export function setSecretAction(secret: string): IAction<string> {
  return {
    type: SET_SECRET,
    payload: secret,
  }
}

export const sendFacebookParamsAction = (uuid: string): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const fbp = getCookie('_fbp')
  const fbc = getCookie('_fbc')
  const appName = selectAppName(state)

  dispatch(startFetching(SEND_FACEBOOK_PARAMS))

  const response = await userApi.saveFacebookParams({
    uuid,
    fbp,
    fbc,
    appName,
  })

  if (!response.success) {
    dispatch(setErrorAction('Something went wrong.'))
  }

  dispatch(stopFetching(SEND_FACEBOOK_PARAMS))
}

export const sendUserEmailAction = ({
  email,
  uuid,
  successCallback,
  unsuccessCallback,
}: {
  email: string
  uuid: string
  successCallback: () => void
  unsuccessCallback: () => void
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const appName = selectAppName(state)

  dispatch(startFetching(SEND_USER_EMAIL))

  const response = await userApi.saveUserEmail({
    uuid,
    email,
    appName,
  })

  if (!response.success) {
    if (response.status === 409) {
      dispatch(setErrorAction('You already have an account.'))
    } else {
      dispatch(
        setErrorAction(
          'Something went wrong. Please check the address and try again',
        ),
      )
    }

    eventLogger.logEmailPageError({ error: JSON.stringify(response.data) })

    unsuccessCallback()
    dispatch(stopFetching(SEND_USER_EMAIL))
    return
  }

  eventLogger.logEmailPageCompleted({ email })

  window.fbq('track', 'Lead', {}, { eventID: uuid })
  window.ttq.identify({ email })
  window.ttq.track('CompleteRegistration')
  window.snaptr('track', 'SIGN_UP', {
    user_email: email,
  })
  successCallback()
  dispatch(setUserEmailAction(email))
  dispatch(stopFetching(SEND_USER_EMAIL))
}

export const setUpUUIDAction = ({
  cohort,
  uuid: uuidFromUrl,
  giaApiKey,
  language,
  utmTags,
}: {
  cohort: string
  uuid: string | null
  giaApiKey: string
  language: string
  utmTags: Record<string, string | null>
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuidFromStore = selectUUID(state)
  const appName = selectAppName(state)
  const uuid = uuidFromUrl || uuidFromStore

  if (uuid) {
    dispatch(setUUIDAction(uuid))
    return
  }

  const response = await userApi.createLead(
    cohort,
    giaApiKey,
    language,
    utmTags,
    appName,
  )

  if (response.success) {
    const responseUUID = (response.data as IGetUUIDResponseRaw).value
    dispatch(setUUIDAction(responseUUID))
    dispatch(sendFacebookParamsAction(responseUUID))
    // remove data after new uuid creation
    localStorage.removeItem('planNumber')
  } else {
    console.error('error')
  }
}

export function bindUserAction(
  token: string,
  successCallback: () => void,
): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const appName = selectAppName(state)
    const answers = selectAnswers(state)

    dispatch(startFetching(BIND_USER))

    const response = await userApi.signUpUser({ token, uuid, appName })

    if (response.success && response.data) {
      response.data.custom_token &&
        localStorage.setItem(
          CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
          response.data.custom_token,
        )

      const userStatusResponse = await userApi.getUserStatusV2(uuid, appName)
      const { status, state: appState } = userStatusResponse.data
      const userStatus = getUserStatusFromRawUserStatus(status, appState)

      try {
        const timestamp = Date.now()
        const globalAnswers = prepareAnswersForProfile(answers, timestamp)

        await userApi.createUserProfile({
          token,
          timestamp,
          id: uuidv4(),
          data: globalAnswers,
        })
      } catch (error: any) {
        console.error('Error with profile creation')

        dispatch(setErrorAction('Something went wrong.'))
        dispatch(stopFetching(BIND_USER))
      }

      dispatch(setUserStatusAction(userStatus))
      eventLogger.logAccountCreated({
        method: userStatus.account.loginMethod,
      })
      successCallback()
    } else {
      eventLogger.logAccountCreationFailed({
        error: response?.data?.error,
      })

      if (response.status === 409) {
        const errorMessage = response?.data?.error || ''
        const message = errorMessage.toLowerCase().includes('subscription')
          ? 'You already have an email. Your subscription was refunded.'
          : 'You already have an account.'

        dispatch(setErrorAction(message))
        dispatch(stopFetching(BIND_USER))
        return
      }

      dispatch(setErrorAction('Something went wrong.'))
    }

    dispatch(stopFetching(BIND_USER))
  }
}

export const sendUserInfoAction = ({
  email,
  search,
  successCallback,
  unsuccessCallback,
}: {
  email: string
  search: string
  successCallback: () => void
  unsuccessCallback: () => void
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const answers = selectAnswers(state) as TAnswers
  const uuid = selectUUID(state)
  const urlParams = new URLSearchParams(search)
  const awcParam = urlParams.get('awc') || ''
  const fbp = getCookie('_fbp')
  const fbc = getCookie('_fbc')

  dispatch(startFetching(SEND_USER_INFO))

  const response = await userApi.sendUserInfo({
    uuid,
    email,
    answers,
    fbp,
    fbc,
    awcParam,
  })

  if (!response.success) {
    if (response.status === 409) {
      dispatch(setErrorAction('You already have an account.'))
    } else {
      dispatch(
        setErrorAction(
          'Something went wrong. Please check the address and try again',
        ),
      )
    }

    eventLogger.logEmailPageError({ error: JSON.stringify(response.data) })

    unsuccessCallback()
    dispatch(stopFetching(SEND_USER_INFO))
    return
  }

  eventLogger.logEmailPageCompleted({ email })

  window.fbq('track', 'Lead', {}, { eventID: uuid })
  window.ttq.identify({ email })
  window.ttq.track('CompleteRegistration')
  window.snaptr('track', 'SIGN_UP', {
    user_email: email,
  })
  successCallback()
  dispatch(setUserEmailAction(email))
  dispatch(stopFetching(SEND_USER_INFO))
}

export function getUserStatusAction(uuid: string): TAppActionThunk<any> {
  return async (dispatch, getState) => {
    const state = getState()
    const appName = selectAppName(state)

    dispatch(startFetching(GET_STATUS))

    const response = await userApi.getUserStatusV2(uuid, appName)

    if (response.success && response.data) {
      const { status, state: appState } = response.data

      const userStatus = getUserStatusFromRawUserStatus(status, appState)
      dispatch(setUserStatusAction(userStatus))
      dispatch(setAllAnswersAction(userStatus.onboarding))
      dispatch(setUserEmailAction(userStatus.email))
    }

    if (!response.success && response.status === 404) {
      const { search } = window.location
      const urlParams = new URLSearchParams(search)
      urlParams.delete('uuid')

      // use window.location.search to reload the page with updated query params
      window.location.search = `?${urlParams}`
    }

    dispatch(stopFetching(GET_STATUS))
  }
}

export const sendUserTiktokClidAction = (ttclid: string): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuid = selectUUID(state)

  dispatch(startFetching(SEND_USER_TTCLID))

  const response = await userApi.saveUserTTCLID({ ttclid, uuid })

  if (!response.success) {
    dispatch(setErrorAction('Something went wrong.'))
  }

  dispatch(stopFetching(SEND_USER_TTCLID))
}
