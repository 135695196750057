import { TAnswers, TQuestionAnswers } from 'models/common.model'
import { USER_ANSWER_TO_MARKUP_MAP } from 'root-constants'

export const getQuestionAnswers = (
  answers: Partial<TAnswers> | null,
  fields: string[],
): Partial<TQuestionAnswers> | null => {
  if (!answers) {
    return null
  }

  return Object.entries(answers).reduce((acc, [pageId, answer]) => {
    const fieldName = fields.find((field) => pageId.startsWith(field))

    if (!fieldName) return acc

    return {
      ...acc,
      [fieldName]: Array.isArray(answer)
        ? answer.map((value) => USER_ANSWER_TO_MARKUP_MAP?.[value] || value)
        : USER_ANSWER_TO_MARKUP_MAP?.[answer] || answer,
    }
  }, {} as Partial<TQuestionAnswers>)
}
