import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectEmail } from 'root-redux/selects/user'

export const useSnapPixelInitialization = (): void => {
  const email = useSelector(selectEmail)

  useLayoutEffect(() => {
    window.snaptr('init', 'b3426736-0ce2-4aab-a70c-66492cf08cd4', {
      user_email: email,
    })
    window.snaptr('track', 'PAGE_VIEW', { user_email: email })
  }, [email])
}
